import React, { useCallback, useEffect, useState, useRef, useMemo } from "react";
import { useLocation } from "react-router-dom";
import TextButton from "../../components/Buttons/TextButton/TextButton";
import SearchInput from "./SearchInput/SearchInput";
import AdCard from "./AdCard/AdCard";
import InterestCard from "./InterestCard/InterestCard";
import "./adPage.css";
import NoResults from "./NoAdResults/NoAdResults";
import MapSection from "./MapSection/MapSection";
import InterestModal from "../../components/InterestModal/InterestModal";
import { pluralizeRoomType } from "../../utils/RoomtypeHelper";
import SEOTags from "../../utils/SEOTags";
import Pagination from "./Pagination/Pagination";
/* import SortingField from "./SortingField/SortingField"; */
import useDebouncedNavigate from "./utils/hooks/DebounceNavigate";
import { buildQueryParams } from "./utils/QueryUtils";
import useFilters from "./utils/hooks/useFilters";
import { fetchFilteredAds } from "./api/AdPageAPI";
import { buildFilterParams } from "./utils/BuildFilterParams";
import RoundedButton from "../../components/Buttons/RoundedButton/RoundedButton";
import FilterDropdownButton from "../../components/Buttons/FilterDropdownButton/FilterDropdownButton";
import SpaceDropdown from "./Dropdowns/SpaceDropdown/SpaceDropdown";
import MunicipalityDropdown from "./Dropdowns/MunicipalityDropdown/MunicipalityDropdown";
import TimeDropdown from "./Dropdowns/TimeDropdown/TimeDropdown";
import PriceDropdown from "./Dropdowns/PriceDropdown/PriceDropdown";
import AreaDropdown from "./Dropdowns/AreaDropdown/AreaDropdown";
import MomsDropdown from "./Dropdowns/MomsDropdown/MomsDropdown";
import Spinner from "../../components/Spinner/Spinner";

const AdPage = () => {
  const [showMap, setShowMap] = useState(true);
  const [showSmallMap, setShowSmallMap] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalAds, setTotalAds] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [id, setId] = useState([]);
  const [openDropdown, setOpenDropdown] = useState(null);
  const dropdownRefs = useRef([]);
  /*   const [sortingOption, setSortingOption] = useState("default"); */
  const [allAds, setAllAds] = useState([]);
  const debouncedNavigate = useDebouncedNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const {
    searchTerm,
    selectedMunicipality,
    selectedRoomTypes,
    selectedTime,
    selectedPriceRange,
    selectedAreaRange,
    selectedMoms,
    setSearchTerm,
    setSelectedMunicipality,
    setSelectedRoomTypes,
    setSelectedTime,
    setSelectedPriceRange,
    setSelectedAreaRange,
    setSelectedMoms,
    resetFilters,
    areFiltersActive,
  } = useFilters(location);

  const closeAllDropdowns = useCallback(() => {
    setOpenDropdown(null);
  }, []);

  const toggleDropdown = useCallback((dropdownName) => {
    setOpenDropdown((prevOpenDropdown) => (prevOpenDropdown === dropdownName ? null : dropdownName));
  }, []);

  const handleOutsideClick = useCallback(
    (event) => {
      const clickedOutside = Object.values(dropdownRefs.current).every((ref) =>
        ref ? !ref.contains(event.target) : true
      );
      if (clickedOutside) {
        closeAllDropdowns();
      }
    },
    [closeAllDropdowns]
  );

  useEffect(() => {
    if (openDropdown !== null) {
      document.addEventListener("click", handleOutsideClick);
    }
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [openDropdown, handleOutsideClick]);

  const adsPerPage = 40;

  const renderedAds = id.flatMap((item, index) => [
    <li key={item._id}>
      <AdCard id={item} />
    </li>,
    (index + 1) % 20 === 0 && index + 1 !== id.length ? (
      <li key={`interestCard${index}`}>
        <InterestCard />
      </li>
    ) : null,
  ]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setShowMap(windowWidth < 800 ? false : true);
  }, [windowWidth]);

  useEffect(() => {
    setShowSmallMap(windowWidth < 800 ? true : false);
  }, [windowWidth]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" }); // scrolls to top on first render
  }, []);

  const filterParams = useMemo(
    () => ({
      type_of_room: selectedRoomTypes.join(","),
      municipality: selectedMunicipality.join(","),
      priceTypes: selectedTime.join(","),
      ...(selectedPriceRange.min !== null && {
        min_rent: selectedPriceRange.min,
      }),
      ...(selectedPriceRange.max !== null && {
        max_rent: selectedPriceRange.max,
      }),
      ...(selectedAreaRange.min !== null && {
        min_area: selectedAreaRange.min,
      }),
      ...(selectedAreaRange.max !== null && {
        max_area: selectedAreaRange.max,
      }),
      vat_tax_required: selectedMoms.includes("true") ? "true" : selectedMoms.includes("false") ? "false" : undefined,
    }),
    [selectedRoomTypes, selectedMunicipality, selectedTime, selectedPriceRange, selectedAreaRange, selectedMoms]
  );

  const handleFetchFilteredAds = useCallback(() => {
    fetchFilteredAds({
      searchTerm,
      filterParams,
      currentPage,
      adsPerPage,
      setId,
      setTotalPages,
      setTotalAds,
      setAllAds,
      setIsLoading,
    });
  }, [filterParams, currentPage, searchTerm]);

  const updateURL = useCallback(
    (page = 1) => {
      const filterParams = {
        searchTerm: searchTerm || "",
        selectedMunicipality: selectedMunicipality || [],
        selectedRoomTypes: selectedRoomTypes || [],
        selectedTime: selectedTime || [],
        selectedPriceRange: selectedPriceRange || { min: 0, max: 50000 },
        selectedAreaRange: selectedAreaRange || { min: 0, max: 2000 },
        selectedMoms: selectedMoms || [],
      };
      const queryParamsString = buildQueryParams({ ...filterParams, page });

      const formatForURL = (values) => values.map((v) => v.toLowerCase()).join("-");

      const municipalityPath =
        selectedMunicipality.length > 0 ? `${formatForURL(selectedMunicipality)}` : "allakommuner";

      const roomTypesPath = selectedRoomTypes.length > 0 ? `/${formatForURL(selectedRoomTypes)}` : "";

      const path =
        selectedMunicipality.length === 0 && selectedRoomTypes.length === 0
          ? "/lediga-lokaler"
          : `/lediga-lokaler/${municipalityPath}${roomTypesPath}`;

      // Building the final URL
      const url = `${path}${queryParamsString ? `?${queryParamsString}` : ""}`;

      // Navigate to the updated URL
      debouncedNavigate(url);
    },
    [
      debouncedNavigate,
      searchTerm,
      selectedAreaRange,
      selectedMoms,
      selectedMunicipality,
      selectedPriceRange,
      selectedRoomTypes,
      selectedTime,
    ]
  );
  const handlePageChange = useCallback(
    (page) => {
      window.scrollTo({ top: 0, behavior: "instant" }); // scrolls to top on first render
      setCurrentPage(page);
      updateURL(page);
    },
    [updateURL]
  );

  const prevFiltersRef = useRef({
    selectedMunicipality,
    selectedRoomTypes,
    selectedTime,
    selectedPriceRange,
    selectedAreaRange,
    selectedMoms,
  });

  useEffect(() => {
    const prevFilters = prevFiltersRef.current;
    if (
      JSON.stringify(prevFilters) !==
      JSON.stringify({
        selectedMunicipality,
        selectedRoomTypes,
        selectedTime,
        selectedPriceRange,
        selectedAreaRange,
        selectedMoms,
      })
    ) {
      setCurrentPage(1);
      updateURL(1);
    }

    prevFiltersRef.current = {
      selectedMunicipality,
      selectedRoomTypes,
      selectedTime,
      selectedPriceRange,
      selectedAreaRange,
      selectedMoms,
    };
  }, [
    selectedMunicipality,
    selectedRoomTypes,
    selectedTime,
    selectedPriceRange,
    selectedAreaRange,
    selectedMoms,
    updateURL,
  ]);

  useEffect(() => {
    handleFetchFilteredAds(buildFilterParams);
  }, [
    handleFetchFilteredAds,
    selectedRoomTypes,
    selectedMunicipality,
    selectedTime,
    selectedPriceRange,
    selectedAreaRange,
    selectedMoms,
    searchTerm,
  ]);

  const clearFilters = () => {
    sessionStorage.clear();
    resetFilters();
    updateURL(1);
    setCurrentPage(1);
  };

  /// Title logic

  const getTitle = useCallback(() => {
    const roomTypePluarized =
      selectedRoomTypes.length > 1
        ? "lokaler"
        : selectedRoomTypes.length === 1
        ? pluralizeRoomType(selectedRoomTypes[0])
        : "lokaler";

    const municipalityText =
      selectedMunicipality.length === 1
        ? `i ${selectedMunicipality[0].charAt(0).toUpperCase() + selectedMunicipality[0].slice(1)}`
        : "";
    return `(${totalAds}) Lediga ${roomTypePluarized} ${municipalityText}   `;
  }, [selectedMunicipality, selectedRoomTypes, totalAds]);

  const title = useMemo(() => getTitle(), [getTitle]);

  const url =
    selectedMunicipality.length === 0 && selectedRoomTypes.length === 0
      ? "/lediga-lokaler"
      : `/lediga-lokaler/${encodeURIComponent(
          selectedMunicipality.length > 0 ? selectedMunicipality.join("-") : "allakommuner"
        )}/${encodeURIComponent(selectedRoomTypes.join("-"))}`;

  return (
    <>
      <SEOTags
        title={title + "  "}
        description={
          "Vakansa har ett brett utbud av alla möjliga lokaler som kan hyras i andrahand per timme, dag, vecka, månad och helst i flera år."
        }
        canonicalUrl={url}
        ogImage={"/images/logo/meta-logo.jpg"}
      />
      <main className={`outer-wrapper-ad-section ${showMap ? "two-column" : "single-column"}`}>
        <div className="ad-section-container">
          <div className="ad-section-top-wrapper">
            <div className="ad-section-head-text">
              <h1>
                Lediga{" "}
                {selectedRoomTypes.length > 1
                  ? "lokaler"
                  : selectedRoomTypes.length === 1
                  ? pluralizeRoomType(selectedRoomTypes[0])
                  : "lokaler"}
                {selectedMunicipality.length > 1
                  ? ""
                  : selectedMunicipality.length === 1
                  ? ` i ${selectedMunicipality[0].charAt(0).toUpperCase() + selectedMunicipality[0].slice(1)}`
                  : ""}
              </h1>
              <p>
                Vakansa har ett brett utbud av alla möjliga lokaler som kan hyras i andrahand per timme, dag, vecka,
                månad och helst i flera år.
              </p>
              <div className="ad-section-head-inner">
                <b>Hittar du inte det du söker? </b>
                <b>
                  <div>
                    {" "}
                    <TextButton label="Klicka här" color="green" size="xxs" href="/lokalforslag" /> så hjälper vi dig!
                  </div>
                </b>
                <label className="toggle-small">
                  <div className="ad-setion-toggle-container">
                    <input
                      className="toggle-checkbox"
                      type="checkbox"
                      checked={showSmallMap}
                      onChange={() => setShowSmallMap((prevState) => !prevState)}
                    />
                    <div className="toggle-switch"></div>
                    <p className="toggle-label">Visa karta</p>
                  </div>
                </label>
              </div>
            </div>
            <div className="ad-section-search">
              <SearchInput value={searchTerm} onChange={setSearchTerm} />
              <label className="toggle">
                <div className="ad-setion-toggle-container">
                  <input
                    className="toggle-checkbox"
                    type="checkbox"
                    checked={showMap}
                    onChange={() => setShowMap((prevState) => !prevState)}
                  />
                  <div className="toggle-switch"></div>
                  <p className="toggle-label">Visa karta</p>
                </div>
              </label>
            </div>

            <section className="ad-section-filters" aria-label="filter-section">
              <FilterDropdownButton
                label="Lokaler"
                ref={(el) => (dropdownRefs.current.space = el)}
                dropdownContent={
                  <SpaceDropdown
                    selectedRoomTypes={selectedRoomTypes}
                    setSelectedRoomTypes={setSelectedRoomTypes}
                    closeDropdown={closeAllDropdowns}
                  />
                }
                isDropdownVisible={openDropdown === "space"}
                toggleDropdown={() => toggleDropdown("space")}
                isActive={selectedRoomTypes.length > 0}
              />

              <FilterDropdownButton
                label="Kommuner"
                ref={(el) => (dropdownRefs.current.municipality = el)}
                dropdownContent={
                  <MunicipalityDropdown
                    selectedMunicipality={selectedMunicipality}
                    setSelectedMunicipality={setSelectedMunicipality}
                    closeMunicipalityDropdown={closeAllDropdowns}
                  />
                }
                isDropdownVisible={openDropdown === "municipality"}
                toggleDropdown={() => toggleDropdown("municipality")}
                isActive={selectedMunicipality.length > 0}
              />
              <FilterDropdownButton
                label="Tider"
                ref={(el) => (dropdownRefs.current.time = el)}
                dropdownContent={
                  <TimeDropdown
                    selectedTime={selectedTime}
                    setSelectedTime={setSelectedTime}
                    closeTimeDropdown={closeAllDropdowns}
                  />
                }
                isDropdownVisible={openDropdown === "time"}
                toggleDropdown={() => toggleDropdown("time")}
                isActive={selectedTime.length > 0}
              />

              <FilterDropdownButton
                label="Pris"
                ref={(el) => (dropdownRefs.current.price = el)}
                dropdownContent={
                  <PriceDropdown
                    selectedPriceRange={selectedPriceRange}
                    setSelectedPriceRange={setSelectedPriceRange}
                    closePriceDropdown={closeAllDropdowns}
                  />
                }
                isDropdownVisible={openDropdown === "price"}
                toggleDropdown={() => toggleDropdown("price")}
                isActive={selectedPriceRange.min !== null || selectedPriceRange.max !== null}
              />

              <FilterDropdownButton
                label="Yta"
                ref={(el) => (dropdownRefs.current.area = el)}
                dropdownContent={
                  <AreaDropdown
                    selectedAreaRange={selectedAreaRange}
                    setSelectedAreaRange={setSelectedAreaRange}
                    closeAreaDropdown={closeAllDropdowns}
                  />
                }
                isDropdownVisible={openDropdown === "area"}
                toggleDropdown={() => toggleDropdown("area")}
                isActive={selectedAreaRange.min !== null || selectedAreaRange.max !== null}
              />

              <FilterDropdownButton
                label="Moms"
                ref={(el) => (dropdownRefs.current.moms = el)}
                dropdownContent={
                  <MomsDropdown
                    selectedMoms={selectedMoms}
                    setSelectedMoms={setSelectedMoms}
                    closeMomsDropdown={closeAllDropdowns}
                  />
                }
                isDropdownVisible={openDropdown === "moms"}
                toggleDropdown={() => toggleDropdown("moms")}
                isActive={selectedMoms.length > 0}
              />
            </section>

            <div className="ad-section-top">
              <div className="results-counter">
                <p className="ad-page-results">{totalAds}&nbsp; resultat</p>
              </div>
              <div className="ad-page-button-wrapper">
                <RoundedButton
                  label="Rensa"
                  color="green"
                  size="xs"
                  onClick={clearFilters}
                  disabled={!areFiltersActive()}
                />
                {/*               <SortingField
                sortingOption={sortingOption}
                onSortingOptionChange={setSortingOption}
                />{" "} */}
              </div>
            </div>
          </div>

          {showSmallMap && (
            <div className={`map-placeholder ${showSmallMap ? "map-visible" : ""}`}>
              <div className="map-wrap-time">
                <MapSection mapZoom={4} ad={allAds} />
              </div>
            </div>
          )}

          <div>
            <ul className="ad-cards-container">
              {isLoading ? (
                <div className="spinner-wrapper">
                  <Spinner />
                </div>
              ) : renderedAds.length > 0 ? (
                renderedAds
              ) : (
                <div className="no-result-wrapper">
                  <NoResults id={id} />
                </div>
              )}
            </ul>
            <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
          </div>
        </div>

        {showMap && (
          <div className={`map-placeholder ${showMap ? "map-visible" : ""}`}>
            <div className="map-wrap-time">
              <MapSection mapZoom={5} ad={allAds} />
            </div>
          </div>
        )}
      </main>
      <InterestModal />
    </>
  );
};

export default AdPage;
