import React, { useState } from "react";
import "./Accordion.css";
import arrowDown from "./icons/down-arrow.svg";
import arrowUp from "./icons/up-arrow.svg";

const Accordion = ({ items }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const onTitleClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  const renderedItems = items.map((item, index) => {
    const active = index === activeIndex ? "active" : "";
    const iconSrc = index === activeIndex ? arrowUp : arrowDown;

    return (
      <div key={item.index}>
        <div className={`title ${active}`} onClick={() => onTitleClick(index)}>
          {item.title}
          <span className="icon">
            <img src={iconSrc} alt="arrow icon" />
          </span>
        </div>
        <div className={`content ${active}`}>
          <p>{item.content}</p>
        </div>
      </div>
    );
  });

  return <div className="ui styled accordion">{renderedItems}</div>;
};

export default Accordion;
