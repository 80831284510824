import React, { useRef } from "react";
import "./adCard.css";
import axios from "axios";
import { Image, Placeholder, Transformation } from "cloudinary-react";
import { backend } from "../../../backend";
import { createSEOUrl } from "../../../utils/createSEOUrl";
import { logAxiosError } from "../../../logging/error";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function AdCard({ id, props, hoverEffects }) {
  const hasImages = id.images && id.images.length > 0 && Object.values(id.images[0])[0];

  const handleAdClick = async () => {
    try {
      await axios.post(backend("/api/listings/click"), {
        id: id._id,
      });
    } catch (error) {
      logAxiosError(error);
    }
  };

  const sliderRef = useRef(null);

  const settings = {
    speed: 500,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: id.images && id.images.length > 1,
  };

  const noImage = () => {
    return (
      <svg className="slash-eye-icon" fill="currentColor" viewBox="0 0 16 16" {...props}>
        <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 00-2.79.588l.77.771A5.944 5.944 0 018 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0114.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z" />
        <path d="M11.297 9.176a3.5 3.5 0 00-4.474-4.474l.823.823a2.5 2.5 0 012.829 2.829l.822.822zm-2.943 1.299l.822.822a3.5 3.5 0 01-4.474-4.474l.823.823a2.5 2.5 0 002.829 2.829z" />
        <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 001.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 018 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884l-12-12 .708-.708 12 12-.708.708z" />
      </svg>
    );
  };

  return (
    <article>
      <a
        onClick={handleAdClick}
        className="ad-card-outer-link"
        href={`/lediga-lokaler/${createSEOUrl(id.municipality)}/${createSEOUrl(id.type_of_room)}/${createSEOUrl(
          id.address
        )}/${id._id}`}
      >
        <div className={`search-result-card ${hoverEffects}`}>
          {hasImages ? (
            <>
              <Slider ref={sliderRef} {...settings}>
                {id.images.slice(0, 4).map((image, i) => (
                  <div key={i}>
                    <Image
                      alt={id.type_of_room + " finns att hyra på " + id.address + " i " + id.municipality}
                      fetchFormat="webp"
                      quality="auto:best"
                      loading="lazy"
                      responsiveUseBreakpoints="true"
                      cloudName="vakansa"
                      publicId={`listings/${id._id}/${image}`}
                      crop="fill"
                      width="350"
                      height="250"
                      style={{ margin: "0 auto", width: "100%" }}
                      className="adcard-image"
                    >
                      <Transformation
                        // fetchFormat="auto"
                        // width="350"
                        // height="250"
                        // effect="enhance"
                        effect="sharpen"
                      />
                      <Placeholder type="predominant" />
                    </Image>
                  </div>
                ))}
              </Slider>
            </>
          ) : (
            <div className="adcard-no-image">
              {noImage()}
              <span>Bild inte tillgänglig</span>
            </div>
          )}

          <div className="search-ad-card">
            <div className="search-ad-card-heading">
              <h4>
                {id.address}, {id.city}
              </h4>
              <div>
                <p>{id.municipality} kommun</p>
              </div>
            </div>
            <div className="search-ad-card-info">
              <span>
                {id.type_of_room} / upp till {id.area} m2 {id.workspaces ? `/ ≈ ${id.workspaces} platser` : ""}
              </span>
            </div>
            <div className="search-ad-card-price">
              {id.hourly_rent !== 0 && id.hourly_rent && <span>{id.hourly_rent} kr /tim</span>}

              {id.daily_rent !== 0 && id.daily_rent && <span>{id.daily_rent} kr /dag</span>}

              {id.monthly_rent !== 0 && id.monthly_rent && <span>{id.monthly_rent} kr /mån</span>}

              {id.workspace_rent !== 0 && id.workspace_rent && <span>{id.workspace_rent} kr /plats</span>}
            </div>
          </div>
        </div>
      </a>
    </article>
  );
}
