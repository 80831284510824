import React from "react";
import Renter from "./Renter/Renter";
import SEOTags from "../../utils/SEOTags";
import "./forRenter.css";


export default function ForRenter() {
  return (
    <>
      <SEOTags
        title={"Hyra en lokal   "}
        description={
          "Upptäck och hitta din perfekta lokal enkelt med Vakansa - anpassat efter dina behov och önskemål!"
        }
        canonicalUrl={"/hyr-en-lokal"}
        ogImage={"/images/logo/meta-logo.jpg"}
      />
      <main>
        <div className="forrenter-container">
          <div className="forrenter-intro-content">
            <div className="forrenter-intro-text">
              <h1>Hyra en lokal</h1>
              <h2>Hitta Din Perfekta Lokal med Vakansa</h2>
              <p>
                Att hitta rätt lokal för ditt företag, förening och organisation
                har aldrig varit enklare. På <a href={"/"}>Vakansa.se</a> guidar
                vi dig smidigt genom processen att hitta och hyra den perfekta
                lokalen som matchar dina behov och önskemål. Följ dessa enkla
                steg för att komma igång:
              </p>
              <br />
              <br />
              <ol>
                <li>
                  <b>Utforska lokaler:</b> Börja med att utforska vårt
                  omfattande utbud av tillgängliga lokaler. Använd vår
                  användarvänliga filtreringsfunktion för att snabbt sortera och
                  hitta lokaler som uppfyller dina specifikationer{" "}
                  <a href={"/lediga-lokaler"}>här</a>.
                </li>
                <li>
                  <b>Ange dina preferenser:</b> Berätta för oss vad du söker
                  genom att fylla i dina kriterier såsom typ av lokal, önskad
                  storlek, geografiskt läge och budget. Ju mer detaljerad
                  information du ger, desto bättre matchade lokaler kan vi
                  föreslå.
                </li>
                <li>
                  <b>Skicka förfrågan:</b> När du har hittat en eller flera
                  lokaler som fångar ditt intresse, kan du enkelt skicka en
                  förfrågan med ditt intresse och få svar på alla dina frågor
                  direkt av hyresvärden. På så sätt kan du enkelt boka en
                  visning. Besök lokalen personligen och skaffa dig en bättre
                  känsla för utrymmet och hur det passar till dina
                  verksamhetsbehov.
                </li>
                <li>
                  <b>Ta ett beslut:</b> När du har hittat din idealiska lokal
                  och är redo att gå vidare, kan Vakansa hjälpa dig genom hela
                  processen att säkra lokalen.
                </li>
                <li>
                  <b>Flytta in och gör dig hemmastadd:</b> Efter ett
                  framgångsrikt avtal är det dags att flytta in i din nya lokal.
                  Vakansa finns där för att säkerställa att allt går smidigt,
                  från start till slut.
                </li>
              </ol>
              <br />
              <br />
              <p>
                På Vakansa kan vi hjälpa er med allt från avtalsmallar,
                avtalsskrivning, bokningssystem, nyckelhanteringssystem,
                försäkring, städning och annan administration vid behov!
              </p>
              <br />
              <p>
                På Vakansa är vi dedikerade till att göra processen att hitta
                och hyra lokaler så enkel och problemfri som möjligt. Besök oss
                på <a href={"/"}>Vakansa.se</a> och låt oss hjälpa dig att hitta
                den perfekta platsen för ditt företag idag.”
              </p>
            </div>
          </div>
        </div>
        <Renter />
      </main>
    </>
  );
}
